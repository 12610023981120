import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(candidateId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`candidate/${candidateId}/education`, page, perPage, search, sortBy, descending, params);
}

/**
 * @param educationId {number}
 */
function show(educationId) {
  return get(`education/${educationId}`);
}

/**
 * @param candidateId {number}
 * @param educationFormData {Education}
 */
function create(candidateId, educationFormData) {
  return post(`candidate/${candidateId}/education`, educationFormData);
}

/**
 * @param educationFormData {Education}
 */
function update(educationFormData) {
  return put(`education/${educationFormData.id}`, educationFormData);
}

/**
 * @param educationId {number}
 */
function remove(educationId) {
  return destroy(`education/${educationId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
};
