
import Vue from 'vue';
import Experience from '@/application/models/Experience.js';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import dayjs from '@/plugins/dayjs';
import { Dayjs } from 'dayjs';

interface Panel {
  name: string;
}

export default Vue.extend({
  name: 'ExperienceForm',
  components: {
    KDateField,
    KTextarea,
    KTextField,
    KFieldGroup,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
      default: new Experience(),
    },
  },
  computed: {
    panels(): Panel[] {
      return [
        { name: this.$t('candidate.background.general') as string },
      ];
    },
    minDate(): Dayjs {
      return dayjs().subtract(70, 'year');
    },
    maxDate(): Dayjs {
      return dayjs();
    },
  },
});
