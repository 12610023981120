<template>
  <k-dossier>
    <k-small-buttons :flow-buttons="[{ text: $t('candidate.background.add'), handle: handleCreateExperience }]">
      <k-subtitle>{{ $tc('experience.title', 1) }}</k-subtitle>
      <ExperienceForm
        v-model="createDialog"
        :request="createRequestExperience"
        :title="$tc('experience.title', 1)"
        :values="createFormValues"
        @change="getExperiences"
      />
    </k-small-buttons>
    <v-row
      v-for="(experience, index) in experiences"
      :key="index"
      class="word-break--all"
    >
      <v-col cols="10">
        <v-row dense>
          <v-col
            cols="4"
            lg="3"
          >
            {{ $t('experience.fields.employer') }}:
          </v-col>
          <v-col
            cols="8"
            lg="9"
          >
            {{ experience.employer }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="4"
            lg="3"
          >
            {{ $t('experience.fields.position') }}:
          </v-col>
          <v-col
            cols="8"
            lg="9"
          >
            {{ experience.position }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="4"
            lg="3"
          >
            {{ $t('candidate.background.period') }}:
          </v-col>
          <v-col
            cols="8"
            lg="9"
          >
            {{ startDate(experience.startDate) }} {{ endDate(experience.endDate) }}
            {{ formatExperienceTimeInTextI18n(experience.startDate, experience.endDate) }}
          </v-col>
        </v-row>

        <v-expand-transition v-if="experience.description">
          <v-row
            v-if="isExpansionOpen(index)"
            dense
          >
            <v-col
              cols="4"
              lg="3"
            >
              {{ $t('experience.fields.description') }}:
            </v-col>
            <v-col
              class="nl2br"
              cols="8"
              lg="9"
            >
              {{ experience.description }}
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
      <v-col cols="2">
        <v-row
          class="ma-0"
          dense
        >
          <VSpacer />
          <v-btn
            color="primary"
            icon
            small
            @click.stop="openUpdateExperience(experience)"
          >
            <v-icon small>
              $edit
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click.stop="destroyExperience(experience)"
          >
            <v-icon small>
              fa-trash-alt
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        :style="{ marginTop: '-12px' }"
        class="py-0"
        cols="12"
      >
        <v-row
          v-if="experience.description"
          class="justify-center"
          no-gutters
        >
          <v-btn
            icon
            @click="toggleExpansion(index)"
          >
            <v-icon
              :class="isExpansionOpen(index) ? 'open': 'closed'"
              class="expand-icon"
            >
              $expand
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="experiences.length === 0"
      dense
    >
      <v-col>
        <p class="font-weight-regular">
          {{ $t('experience.notFound') }}
        </p>
      </v-col>
    </v-row>
    <ExperienceForm
      v-model="updateDialog"
      :request="updateRequestExperience"
      :title="$tc('candidate.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="getExperiences"
    />
  </k-dossier>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Experience from '@/application/models/Experience.js';
import formatExperienceTimeInTextI18n from '@/application/util/experienceTime.js';
import { create, index, remove, show, update } from '@/modules/candidate/api/experience.js';
import KSmallButtons from '@/components/KSmallButtons.vue';
import ExperienceForm from '@/modules/candidate/components/ExperienceForm.vue';
import dayjs from '@/plugins/dayjs';
import KDossier from '@/components/layout/KDossier.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';

export default {
  name: 'ExperienceCard',
  components: {
    KSubtitle,
    KDossier,
    ExperienceForm,
    KSmallButtons,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  data() {
    return {
      openPanels: [],
      experiences: [],
      createDialog: false,
      createFormValues: new Experience(),
      updateDialog: false,
      updateFormValues: new Experience(),
    };
  },
  watch: {
    '$route.params.candidateId': {
      immediate: true,
      handler() {
        this.getExperiences();
      },
    },
  },
  methods: {
    formatExperienceTimeInTextI18n,
    isExpansionOpen(index) {
      return this.openPanels.includes(index);
    },
    toggleExpansion(index) {
      if (this.isExpansionOpen(index)) {
        this.openPanels = this.openPanels.filter((item) => item !== index);
        return;
      }
      this.openPanels.push(index);
    },

    startDate(date) {
      return dayjs(date).format('MMM YYYY');
    },
    endDate(date) {
      return `- ${date ? dayjs(date).format('MMM YYYY') : this.$t('candidate.background.presentDay')
        .toLowerCase()}`;
    },
    async getExperiences() {
      const { data: { data } } = await index(this.$route.params.candidateId);
      this.experiences = data.map((experience) => new Experience().mapResponse(experience));
    },
    handleCreateExperience() {
      this.createFormValues = new Experience();
      this.createDialog = true;
    },
    createRequestExperience() {
      return create(this.candidate.id, this.createFormValues);
    },
    async openUpdateExperience(item) {
      this.updateFormValues = new Experience();
      const { data: { data } } = await show(item.id);
      this.updateFormValues.mapResponse(data);
      this.updateDialog = true;
    },
    updateRequestExperience() {
      return update(this.updateFormValues);
    },
    async destroyExperience(experience) {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('actions.deleteConfirmation.body', { resource: this.$tc('experience.title', 1) }),
        confirmCallback: async () => {
          await remove(experience.id);
          await this.getExperiences();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-icon {
  :deep(&.open) {
    transform: rotate(-180deg);
  }
}
</style>
