import Model from '@/application/models/model.js';

class Education extends Model {
  id = '';
  name = '';
  institution = '';
  startMonth = '';
  startYear = '';
  endMonth = '';
  endYear = '';
  hasCertificate = 0;
  description = '';

  mapForRequest() {
    return {
      id: this.id,
      name: this.name,
      institution: this.institution,
      startMonth: this.startMonth,
      startYear: this.startYear,
      endMonth: this.endMonth,
      endYear: this.endYear,
      hasCertificate: ['', false, true][this.hasCertificate],
      description: this.description,
    };
  }

  mapResponse(data) {
    return this.initializeObjectFromApi({
      ...data,
      hasCertificate: {
        'null': 0,
        'false': 1,
        'true': 2,
      }[`${data.hasCertificate}`],
    }, this);
  }
}

export default Education;
