<template>
  <k-field-group language-prefix="education.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      add-another
      v-on="$listeners"
    >
      <template #panel.0>
        <v-row no-gutters>
          <KTextField
            v-model="values.name"
            field="name"
            grid="pr-2"
            required
          />

          <KTextField
            v-model="values.institution"
            field="institution"
            grid="pr-2"
            required
          />

          <KTextField
            v-model="values.startYear"
            prepend-icon="$date"
            type="number"
            field="yearStart"
            :value="lastYear"
            grid="col-sm-6 pr-4"
            :max="maxYear"
            :min="minYear"
            required
          />
          <KTextField
            v-model="values.endYear"
            prepend-icon="$date"
            :value="thisYear"
            type="number"
            field="yearEnd"
            grid="col-sm-6 px-2"
            :max="maxYear"
            :min="minYear"
          />

          <KRadioGroup
            v-model="values.hasCertificate"
            :items="educationStateOptions"
            field="hasCertificate.state"
            grid="col-12 pr-2"
            column
          />

          <KTextarea
            v-model="values.description"
            field="description"
            grid="col-sm-12 pr-2"
          />
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import Education from '@/application/models/Education.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'EducationForm',
  components: {
    KRadioGroup,
    KTextarea,
    KTextField,
    KFieldGroup,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
      default: new Education(),
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('candidate.background.general') },
      ];
    },
    thisYear() {
      return dayjs().format('YYYY');
    },
    lastYear() {
      return dayjs().subtract(1, 'year').format('YYYY');
    },
    minYear() {
      return dayjs().subtract(150, 'year').format('YYYY');
    },
    maxYear() {
      return dayjs().add(10, 'year').format('YYYY');
    },
    educationStateOptions() {
      return [
        {
          text: this.$t('education.fields.hasCertificate.notYetFinished'),
          value: 0,
        }, {
          text: this.$t('education.fields.hasCertificate.didNotFinish'),
          value: 1,
        }, {
          text: this.$t('education.fields.hasCertificate.finished'),
          value: 2,
        }];
    },
  },
};
</script>
