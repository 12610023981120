<template>
  <v-row
    no-gutters
    align="center"
    class="ma-n3"
  >
    <v-col
      class="d-inline-flex justify-start"
      cols="auto"
    >
      <slot />
    </v-col>
    <v-col
      v-for="(buttonData, index) in flowButtons"
      :key="index"
      class="d-inline-flex justify-end"
    >
      <v-btn
        v-if="!buttonData.hidden"
        class="mx-1"
        color="primary"
        depressed
        small
        tile
        @click="buttonData.handle"
      >
        {{ buttonData.text }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'KSmallButtons',
  props: {
    flowButtons: {
      type: Array,
    },
  },
};
</script>
