<template>
  <PageLayout>
    <template #header>
      <k-title>
        {{ $t('candidate.background.title') }} {{ $t('candidate.background.subtitle') }}
        {{ candidate.name }}
      </k-title>
    </template>
    <template
      v-if="candidate"
      #default
    >
      <v-row>
        <v-col sm="6">
          <ExperienceCard
            :candidate="candidate"
            :get-candidate="getCandidate"
          />
        </v-col>
        <v-col>
          <EducationCard
            :candidate="candidate"
            :get-candidate="getCandidate"
          />
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import PageLayout from '@/components/layout/PageLayout.vue';
import EducationCard from '@/modules/candidate/components/EducationCard.vue';
import ExperienceCard from '@/modules/candidate/components/ExperienceCard.vue';
import KTitle from '@/components/layout/KTitle.vue';

export default {
  name: 'Background',
  components: {
    KTitle,
    ExperienceCard,
    EducationCard,
    PageLayout,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      },
      {
        exact: true,
        to: { name: 'candidate.background' },
        text: this.$t('candidate.background.title'),
      }],
    );
  },
};
</script>
