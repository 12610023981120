import Model from '@/application/models/model.js';
import dayjs from '@/plugins/dayjs.ts';

const apiFormat = 'YYYY-M-D';
const formFormat = 'YYYY-MM-DD';
const formatDate = (dateString, template = formFormat) => {
  if (!dateString) return '';
  return dayjs(dateString, apiFormat).format(template);
};

class Experience extends Model {
  id = '';
  employer = '';
  position = '';
  description = '';
  startDate = '';
  endDate = '';

  mapForRequest() {
    return {
      id: this.id,
      employer: this.employer,
      position: this.position,
      startMonth: formatDate(this.startDate, 'M'),
      startYear: formatDate(this.startDate, 'YYYY'),
      endMonth: formatDate(this.endDate, 'M'),
      endYear: formatDate(this.endDate, 'YYYY'),
      description: this.description,
    };
  }

  mapResponse(data) {
    const { startMonth, startYear, endMonth, endYear } = data;
    const startDate = formatDate(`${startYear}-${startMonth}-1`, formFormat);
    const endDate = endYear && formatDate(`${endYear}-${endMonth}-1`, formFormat);
    return super.mapResponse({
      ...data,
      startDate,
      endDate,
    });
  }
}

export default Experience;
