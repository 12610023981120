import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

function index(candidateId, page, perPage, search, sortBy, descending, params) {
  return getPaginated(`candidate/${candidateId}/experience`, page, perPage, search, sortBy, descending, params);
}

/**
 * @param experienceId {number}
 */
function show(experienceId) {
  return get(`experience/${experienceId}`);
}

/**
 * @param candidateId {number}
 * @param formData {Experience}
 */
function create(candidateId, formData) {
  return post(`candidate/${candidateId}/experience`, formData);
}

/**
 * @param formData {Experience}
 */
function update(formData) {
  return put(`experience/${formData.id}`, formData);
}

/**
 * @param experienceId {number}
 */
function remove(experienceId) {
  return destroy(`experience/${experienceId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
};
