<template>
  <k-dossier>
    <k-small-buttons :flow-buttons="[{ text: $t('candidate.background.add'), handle: handleCreateEducation }]">
      <k-subtitle>{{ $tc('education.title', 1) }}</k-subtitle>
      <EducationForm
        v-model="createDialog"
        :request="createRequestEducation"
        :title="$tc('education.title', 1)"
        :values="createFormValues"
        @change="getEducations"
      />
    </k-small-buttons>
    <v-row
      v-for="(education, index) in educations"
      :key="index"
    >
      <v-col cols="10">
        <v-row dense>
          <v-col cols="3">
            {{ $t('education.fields.name') }}:
          </v-col>
          <v-col cols="9">
            {{ education.name }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            {{ $t('education.fields.institution') }}:
          </v-col>
          <v-col cols="9">
            {{ education.institution }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            {{ $t('candidate.background.period') }}:
          </v-col>
          <v-col cols="9">
            {{ education.startYear }} -
            {{ education.endYear ? education.endYear : $t('candidate.background.presentDay').toLowerCase() }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="3">
            {{ $t('candidate.background.finished') }}:
          </v-col>
          <v-col cols="9">
            {{ hasCertificateText(education.hasCertificate) }}
          </v-col>
        </v-row>

        <v-expand-transition v-if="education.description">
          <v-row
            v-if="isExpansionOpen(index)"
            dense
          >
            <v-col cols="3">
              {{ $t('education.fields.description') }}:
            </v-col>
            <v-col
              class="nl2br"
              cols="9"
            >
              {{ education.description }}
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
      <v-col cols="2">
        <v-row
          class="ma-0"
          dense
        >
          <VSpacer />
          <v-btn
            color="primary"
            icon
            small
            @click.stop="openUpdateEducation(education)"
          >
            <v-icon small>
              $edit
            </v-icon>
          </v-btn>
          <v-btn
            icon
            small
            @click.stop="destroyEducation(education)"
          >
            <v-icon small>
              $delete
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        :style="{ marginTop: '-12px' }"
        class="py-0"
        cols="12"
      >
        <v-row
          v-if="education.description"
          class="justify-center"
          no-gutters
        >
          <v-btn
            icon
            @click="toggleExpansion(index)"
          >
            <v-icon
              :class="isExpansionOpen(index) ? 'open': 'closed'"
              class="expand-icon"
            >
              $expand
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="educations.length === 0"
      dense
    >
      <v-col>
        <p class="font-weight-regular">
          {{ $t('education.notFound') }}
        </p>
      </v-col>
    </v-row>
    <EducationForm
      v-model="updateDialog"
      :request="updateRequestEducation"
      :title="$tc('candidate.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="getEducations"
    />
  </k-dossier>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Education from '@/application/models/Education.js';
import { create, index, remove, show, update } from '@/modules/candidate/api/education.js';
import KSmallButtons from '@/components/KSmallButtons.vue';
import EducationForm from '@/modules/candidate/components/EducationForm.vue';
import KDossier from '@/components/layout/KDossier.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';

export default {
  name: 'EducationCard',
  components: {
    KDossier,
    KSubtitle,
    EducationForm,
    KSmallButtons,
  },
  props: {
    candidate: {
      type: Object,
    },
    getCandidate: {
      type: Function,
    },
  },
  data() {
    return {
      openPanels: [],
      educations: [],
      createDialog: false,
      createFormValues: new Education(),
      updateDialog: false,
      updateFormValues: new Education(),
    };
  },
  watch: {
    '$route.params.candidateId': {
      immediate: true,
      handler() {
        this.getEducations();
      },
    },
  },
  methods: {
    isExpansionOpen(index) {
      return this.openPanels.includes(index);
    },
    toggleExpansion(index) {
      if (this.isExpansionOpen(index)) {
        this.openPanels = this.openPanels.filter((item) => item !== index);
        return;
      }
      this.openPanels.push(index);
    },
    hasCertificateText(hasCertificate) {
      if (hasCertificate === false) {
        return this.$t('education.fields.hasCertificate.didNotFinish');
      }
      if (hasCertificate) {
        return this.$t('education.fields.hasCertificate.finished');
      }
      return this.$t('education.fields.hasCertificate.notYetFinished');

    },
    async getEducations() {
      const { data: { data } } = await index(this.$route.params.candidateId);
      this.educations = data.map((education) => new Education().mapResponse(education));
    },
    handleCreateEducation() {
      this.createFormValues = new Education();
      this.createDialog = true;
    },
    createRequestEducation() {
      return create(this.candidate.id, this.createFormValues);
    },
    async openUpdateEducation(item) {
      this.updateFormValues = new Education();
      const educationShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(educationShowRequest.data.data);
      this.updateDialog = true;
    },
    updateRequestEducation() {
      return update(this.updateFormValues);
    },
    async destroyEducation(education) {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('education.deleteConfirmation'),
        confirmCallback: async () => {
          await remove(education.id);
          await this.getEducations();
        },
      });
    },
  },
}
;
</script>

<style lang="scss" scoped>
.expand-icon {
  :deep(&.open) {
    transform: rotate(-180deg);
  }
}
</style>
